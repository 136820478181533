<template>
	<b-card class="pb-2" no-body>
		<div class="m-2">
			<!-- Table Top -->
			<b-row>
				<!-- Search -->
				<!-- <b-col cols="12" md="2" class="mb-1 mb-md-0">
					<v-select
						class="mr-1"
						v-model="statusFilter"
						:searchable="false"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="statusOptions"
						:placeholder="$t('Please Select')"
						style="width:100%"
					>
					</v-select>
				</b-col>
				<b-col cols="8" md="2" class="mb-1 mb-md-0">
					<b-form-input
						v-model="searchQuery"
						class="d-inline-block"
						:placeholder="$t('Please Enter...')"
						style="width:100%"
					/>
				</b-col>
				<b-col cols="4" md="2">
					<b-button
						variant="primary"
						@click="searchAction"
					>
						<span class="text-nowrap">{{$t('Search')}}</span>
					</b-button>
				</b-col> -->
				<b-col
					cols="12"
					md="12"
					class="d-flex align-items-center justify-content-start justify-content-md-start"
				>
					<b-button variant="primary" @click="newAction">
						<span class="text-nowrap">{{$t('Add Schedule')}}</span>
					</b-button>
				</b-col>
			</b-row>
		</div>
		<!-- data table start -->
		<b-table
			ref="deviceList"
			class="position-relative"
			stacked="sm"
			primary-key="id"
			show-empty
			:empty-text="$t('No Data')"
			hover
			:items="dataList"
			:fields="tableColumns"
			v-loading="loading"
		>
			<!-- Column: Size -->
			<template #cell(size)="data">
				{{data.item.width}}px * {{data.item.height}}px
			</template>
			<!-- Column: status -->
			<template #cell(status)="data">
				
				<b-badge :variant="statusObj[data.item.status].color" v-if="statusObj[data.item.status]">{{statusObj[data.item.status].label}}</b-badge>
			</template>
			<!-- Column: Actions -->
			<template #cell(actions)="data">
				<!-- 重命名 -->
				<feather-icon
					:id="`${data.item.id}-rename-icon`"
					icon="Edit3Icon"
					class="cursor-pointer mr-1"
					size="16"
					@click="renameAction(data.item)"
					
				/>
				<b-tooltip
					:title="$t('Rename')"
					class="cursor-pointer"
					:target="`${data.item.id}-rename-icon`"
				/>
				<!-- 编辑 -->
				<feather-icon
					:id="`${data.item.id}-info-icon`"
					icon="EditIcon"
					class="cursor-pointer mr-1"
					size="16"
					@click="$router.push({ name: 'scheduleDetail', query: { id: data.item.id }})"
				/>
				<b-tooltip
					:title="$t('Edit')"
					class="cursor-pointer"
					:target="`${data.item.id}-info-icon`"
				/>
				<!-- 发布 -->
				<feather-icon
					:id="`${data.item.id}-publish-icon`"
					icon="SendIcon"
					class="cursor-pointer mr-1"
					size="16"
					@click="publishAction(data.item)"
				/>
				<b-tooltip
					:title="$t('Publish')"
					class="cursor-pointer"
					:target="`${data.item.id}-publish-icon`"
				/>
				<!-- 屏幕 -->
				<!-- <feather-icon
					:id="`${data.item.id}-screen-icon`"
					icon="TvIcon"
					class="cursor-pointer mr-1"
					size="16"
					@click="screenAction(data.item)"
				/>
				<b-tooltip
					:title="$t('Screen')"
					class="cursor-pointer"
					:target="`${data.item.id}-screen-icon`"
				/> -->
				
				<!-- 撤销 -->
				<!-- <template v-if="data.item.status==1">
					<feather-icon
						:id="`${data.item.id}-undo-icon`"
						icon="RotateCcwIcon"
						class="cursor-pointer mr-1"
						size="16"
						@click="undoAction(data.item)"
					/>
					<b-tooltip
						:title="$t('Undo')"
						class="cursor-pointer"
						:target="`${data.item.id}-undo-icon`"
					/>
				</template> -->
				<!-- 删除 -->
				<!-- <template v-else>
					<feather-icon
						:id="`${data.item.id}-delete-icon`"
						icon="Trash2Icon"
						class="cursor-pointer mr-1"
						size="16"
						@click="deleteAction(data.item)"
					/>
					<b-tooltip
						:title="$t('Delete')"
						class="cursor-pointer"
						:target="`${data.item.id}-delete-icon`"
					/>
				</template> -->
				
			</template>

		</b-table>
		<!-- Pagination -->
		<Pagination
			:currentPage="currentPage"
			:pageSize="pageSize"
			:count="dataList.length"
			class="mt-2"
			v-if="dataList.length>0"
		></Pagination>

		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
		</Function>
		<!-- New  -->
		<New
			v-if="newSidebarVisible"
			:is-active.sync="newSidebarVisible"
			@refetch-data="getData()"
		/>
		<!-- rename  -->
		<rename
			v-if="renameSidebarVisible"
			:is-active.sync="renameSidebarVisible"
			:activeData="activeData"
			@refetch-data="getData()"
		/>
	</b-card>
</template>

<script>
import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BSidebar,VBToggle, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import New from "@/components/schedule/New.vue"
import Rename from "@/components/schedule/Rename.vue"
import Pagination from "@/components/public/Pagination.vue";
import { getSchedule } from "@/libs/apis/serverApi";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia, 
		BAvatar, 
		BLink,
		BBadge, 
		BDropdown, 
		BDropdownItem, 
		BPagination,
		BTooltip,
		BSidebar,
		BListGroup, BListGroupItem,
		vSelect,

		New,
		Rename,
		Pagination
	},
	directives: {
		'b-toggle': VBToggle,
	},
	data () {
		return  {
			searchQuery: '',
			statusFilter: '',
			// 0 未发布 1 已发布 2 撤销
			statusOptions: [
				{ label: this.$t('All'), value: '-1' },
				{ label: this.$t('Published'), value: '1', color: 'success' },
				{ label: this.$t('Undone'), value: '2', color: 'danger' },
			],
			sortBy: 'id',
			isSortDirDesc: true,
			tableColumns: [
				{ key: 'id', sortable: false },
				{ key: 'name', label:this.$t('Name'), sortable: false },
				{ key: 'created_at', label: this.$t('Create Time'), sortable: false },
				{ key: 'updated_at', label: this.$t('Update Time'), sortable: false },
				{ key: 'actions', label: this.$t('Actions') },
			],
			loading: false,
			dataList: [],
			pageSize: 12,
			currentPage: 1,
			activeData: null,
			newSidebarVisible: false,
			renameSidebarVisible: false,
		}
	},
	computed: {
		statusObj () {
			let temp = {}
			this.statusOptions.forEach(item=>{
				temp[item.value] = item
			})
			return temp
		}
	},
	created () {
		this.getData()
	},
	methods: {
		getData(page) {
			this.loading = true
			page = page || 1;
			getSchedule({
				query: this.searchQuery,
				type: this.activeType,
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows;
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast(err.message)
			});
		},
		searchAction () {},
		newAction () {
			this.newSidebarVisible = true
		},
		renameAction (item){
			this.activeData = item
			this.renameSidebarVisible = true
		},
		screenAction (item){
			this.$router.push('/scheduleScreen?id='+item.id)
		},
		publishAction (item){
			this.$router.push('/schedulePublish?id='+item.id)
		},
		undoAction(item){
			this.$bvModal
				.msgBoxConfirm(this.$t('undoDataTip'), {
					title: this.$t('Tips'),//'Please Confirm',
					size: 'sm',
					okVariant: 'danger',
					okTitle: this.$t('ConfirmUndo'),
					cancelTitle: this.$t('Cancel'),
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(value => {
					// 
				})
		},
		deleteAction(item){
			this.$bvModal
				.msgBoxConfirm(this.$t('deleteDataTip'), {
					title: this.$t('Tips'),//'Please Confirm',
					size: 'sm',
					okVariant: 'danger',
					okTitle: this.$t('Delete'),
					cancelTitle: this.$t('Cancel'),
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(value => {
					// 
				})
		}
	}
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
