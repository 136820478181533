<template>
  	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-active', val)"
  	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{$t('Add Schedule')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>
			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<!-- Name -->
					<validation-provider
						#default="validationContext"
						:name="$t('Name')"
						rules="required"
					>
						<b-form-group
							:label="$t('Name')"
							label-for="name"
						>
							<b-form-input
								id="name"
								v-model="formData.name"
								:state="getValidationState(validationContext)"
								trim
								:placeholder="$t('Please Enter...')"
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>
							{{$t('Add')}}
						</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							{{$t('Cancel')}}
						
						</b-button>
					</div>

				</b-form>
			</validation-observer>
		</template>
  	</b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BFormInvalidFeedback, BButton,BFormFile
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { createSchedule } from "@/libs/apis/serverApi";

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
        BInputGroup,
		BFormInvalidFeedback,
		BButton,
		BFormFile,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
		type: Boolean,
		required: true,
		}
	},
	data() {
		return {
			required,
		}
	},
	setup(props, { emit }) {
		const blankFormData = {
			name: ''
		}

		const formData = ref(JSON.parse(JSON.stringify(blankFormData)))
		const resetformData = () => {
			formData.value = JSON.parse(JSON.stringify(blankFormData))
		}

		const onSubmit = () => {
			createSchedule(formData.value).then(res=>{
				emit('refetch-data')
				emit('update:is-active', false)
			})
			
		}

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetformData)

		return {
			formData,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,
		}
	},
	create () {
		// localize('zh_CN',zh_CN)
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
